const canvasUndo = [];

const rgbToHex = (r, g, b) => {
  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

const undoChanges = () => {
  resetVector();
  if (canvasUndo.length > 0) {
    var canvas = document.getElementById("canvas");
    var context = canvas.getContext("2d");

    context.clearRect(0, 0, canvas.width, canvas.height);

    var img = new Image();
    img.src = canvasUndo[canvasUndo.length - 1];

    img.onload = () => context.drawImage(img, 0, 0);

    canvasUndo.pop();
  } else {
    toolTrash();
  }
};

const relativePos = (event, element) => {
  var rect = element.getBoundingClientRect();
  const touch = event.touches[0];
  return {
    x: Math.floor(touch?.clientX - rect.left),
    y: Math.floor(touch?.clientY - rect.top),
  };
};

const deltaE = (rgbA, rgbB) => {
  //Retorno
  // <= 1    - Não perceptivel para olhos humanos
  // 1 ~ 2   - Pouco perceptivel
  // 2 ~ 10  - Perceptivel
  // 11 ~ 49 - Cores mais próximas ao oposto
  // 100     - Exato oposto

  function rgb2lab(rgb) {
    let r = rgb[0] / 255,
      g = rgb[1] / 255,
      b = rgb[2] / 255,
      x,
      y,
      z;
    r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
    g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
    b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
    y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.0;
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
    x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
    y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
    z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;
    return [116 * y - 16, 500 * (x - y), 200 * (y - z)];
  }

  let labA = rgb2lab(rgbA);
  let labB = rgb2lab(rgbB);
  let deltaL = labA[0] - labB[0];
  let deltaA = labA[1] - labB[1];
  let deltaB = labA[2] - labB[2];
  let c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
  let c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
  let deltaC = c1 - c2;
  let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
  deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
  let sc = 1.0 + 0.045 * c1;
  let sh = 1.0 + 0.015 * c1;
  let deltaLKlsl = deltaL / 1.0;
  let deltaCkcsc = deltaC / sc;
  let deltaHkhsh = deltaH / sh;
  let i =
    deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
  return i < 0 ? 0 : Math.sqrt(i);
};

const toolBruch = (event, onEnd) => {
  var canvas = document.getElementById("canvas");
  var context = canvas.getContext("2d");

  if (context.fillStyle === "#000000") return;

  canvasUndo.push(canvas.toDataURL());

  function trackDrag(onMove, onEnd) {
    function end(event) {
      window.removeEventListener("touchmove", onMove);
      window.removeEventListener("touchend", end);
      if (onEnd) onEnd(event);
    }
    window.addEventListener("touchmove", onMove);
    window.addEventListener("touchend", end);
  }

  context.lineCap = "round";
  context.lineWidth = 10;

  var pos = relativePos(event, context.canvas);
  trackDrag(function (event) {
    context.beginPath();
    context.globalCompositeOperation = "source-over";
    context.moveTo(pos.x, pos.y);
    pos = relativePos(event, context.canvas);
    context.lineTo(pos.x, pos.y);

    context.stroke();
  }, onEnd);
};

const toolFill = (event) => {
  var canvas = document.getElementById("canvasIMG");
  var context = canvas.getContext("2d");

  function forEachNeighbor(point, fn) {
    fn({ x: point.x - 1, y: point.y }); //N
    fn({ x: point.x + 1, y: point.y }); //S
    fn({ x: point.x, y: point.y - 1 }); //E
    fn({ x: point.x, y: point.y + 1 }); //W
  }

  function isSameColor(data, point1, point2) {
    var offset1 = (point1.x + point1.y * data.width) * 4;
    var offset2 = (point2.x + point2.y * data.width) * 4;

    var rgb1 = [
      data.data[offset1 + 0],
      data.data[offset1 + 1],
      data.data[offset1 + 2],
    ];
    var rgb2 = [
      data.data[offset2 + 0],
      data.data[offset2 + 1],
      data.data[offset2 + 2],
    ];

    if (deltaE(rgb1, rgb2) > 2) return false;

    return true;
  }

  var selectAmbientes = document.getElementById("selectAmbientes");
  if (!selectAmbientes) {
    var canvasRef = document.getElementById("canvas");
    var contextRef = canvasRef.getContext("2d");

    canvasUndo.push(canvasRef.toDataURL());

    contextRef.globalCompositeOperation = "source-over";

    var sample = relativePos(event, contextRef.canvas);
    if (isFinite(sample.x) && isFinite(sample.y)) {
      var pixelData = contextRef.getImageData(sample.x, sample.y, 1, 1).data;
    }

    if (pixelData && pixelData[pixelData.length - 1] > 0) {
      var imageData = contextRef.getImageData(
        0,
        0,
        contextRef.canvas.width,
        contextRef.canvas.height
      );
    } else {
      imageData = context.getImageData(
        0,
        0,
        context.canvas.width,
        context.canvas.height
      );
      sample = relativePos(event, context.canvas);
    }
    
    var isPainted = new Array(imageData.width * imageData.height);
    var toPaint = [sample];

    while (toPaint.length) {
      var current = toPaint.pop();
      var id = current.x + current.y * imageData.width;

      if (isPainted[id]) continue;
      else {
        contextRef.fillRect(current.x, current.y, 1, 1);
        isPainted[id] = true;
      }

      forEachNeighbor(current, function (neighbor) {
        if (
          neighbor.x >= 0 &&
          neighbor.x < imageData.width &&
          neighbor.y >= 0 &&
          neighbor.y < imageData.height &&
          isSameColor(imageData, sample, neighbor)
        ) {
          toPaint.push(neighbor);
        }
      });
    }
  } else {
    canvas.style.background = context.fillStyle;
  }
};

var context = undefined;
const fillVector = (e) => {
  var confirmar = document.getElementById("confirmarVetor");
  var vetor = document.getElementById("vetor").getBoundingClientRect();
  confirmar.style.top = vetor.top + "px";
  confirmar.style.left = vetor.left - 30 + "px";
  context?.fill();

  confirmar.style.backgroundColor = "#ffffff00";
};
const resetVector = () => {
  context = undefined;

  var confirmar = document.getElementById("confirmarVetor");
  confirmar.style.display = "none";
};
const toolVector = (event) => {
  var canvas = document.getElementById("canvas");

  canvasUndo.push(canvas.toDataURL());

  const pos = relativePos(event, canvas);

  if (context) {
    context.lineTo(pos.x, pos.y);
  } else {
    context = canvas.getContext("2d");
    context.beginPath();
    context.moveTo(pos.x, pos.y);

    var confirmar = document.getElementById("confirmarVetor");
    confirmar.style.display = "block";
  }
  context.globalCompositeOperation = "source-over";

  var lineWidth = context.lineWidth;
  context.lineWidth = 3;
  context.stroke();
  context.lineWidth = lineWidth;

  if (event.ctrlKey) {
    fillVector();
    resetVector();
  }
};

const toolErase = (event, onEnd) => {
  var canvas = document.getElementById("canvas");
  var context = canvas.getContext("2d");

  canvasUndo.push(canvas.toDataURL());

  function trackOff(onMove, onEnd) {
    function end(event) {
      window.removeEventListener("touchmove", onMove);
      window.removeEventListener("touchend", end);
      if (onEnd) onEnd(event);
    }
    window.addEventListener("touchmove", onMove);
    window.addEventListener("touchend", end);
  }
  var pos = relativePos(event, context.canvas);

  trackOff(function (event) {
    context.beginPath();
    context.globalCompositeOperation = "destination-out";
    context.lineWidth = 20;
    context.lineJoin = "round";
    context.moveTo(pos.x, pos.y);
    pos = relativePos(event, context.canvas);
    context.lineTo(pos.x, pos.y);
    context.closePath();

    context.stroke();
  }, onEnd);
};

const toolTrash = () => {
  var canvas = document.getElementById("canvas");
  var context = canvas.getContext("2d");

  context.clearRect(0, 0, canvas.width, canvas.height);
};

const minimizaCoresUsadas = (esconderCoresUsadas, setEsconderCoresUsadas) => {
  var obj = document.getElementById("usedColors");
  if (!esconderCoresUsadas) {
    setEsconderCoresUsadas(true);
    obj.animate(
      [
        {
          // from
          opacity: 1,
        },
        {
          // to
          opacity: 0,
        },
      ],
      {
        duration: 500,
        fill: "forwards",
      }
    );
  } else {
    setEsconderCoresUsadas(false);
    obj.animate(
      [
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
      ],
      {
        duration: 500,
        fill: "forwards",
      }
    );
  }
};

const getCorProxima = (padraoCores, cor) => {
  return padraoCores
    .map((padraoCor) => {
      padraoCor.deltaE = deltaE(
        [cor.r, cor.g, cor.b],
        [padraoCor.r, padraoCor.g, padraoCor.b]
      );
      return padraoCor;
    })
    .sort((a, b) => a.deltaE - b.deltaE);
};

export {
  toolBruch,
  toolFill,
  toolVector,
  toolErase,
  relativePos,
  deltaE,
  fillVector,
  resetVector,
  undoChanges,
  rgbToHex,
  toolTrash,
  getCorProxima,
  minimizaCoresUsadas,
};
