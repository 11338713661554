import { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import html2canvas from "html2canvas";
import compColors from "complementary-colors";
import "./App.css";
import HeaderProcess from "./components/HeaderProcess";
import logo from "./assets/logo.png";
import Select from "react-select";

import { ReactComponent as Pincel } from "./icons/pincel.svg";
import { ReactComponent as Rolo } from "./icons/rolo.svg";
import { ReactComponent as Vetor } from "./icons/vetor.svg";
import { ReactComponent as Borracha } from "./icons/borracha.svg";
import { ReactComponent as Save } from "./icons/save.svg";
import { ReactComponent as Undo } from "./icons/undo.svg";
import { ReactComponent as Trash } from "./icons/trash.svg";
import { ReactComponent as EscondeCoresUsadas } from "./icons/escondeCoresUsadas.svg";
import { ReactComponent as MostraCoresUsadas } from "./icons/mostraCoresUsadas.svg";
import { ReactComponent as Confirmar } from "./icons/confirm.svg";
import {
  toolBruch,
  toolFill,
  toolVector,
  toolErase,
  relativePos,
  deltaE,
  fillVector,
  resetVector,
  undoChanges,
  rgbToHex,
  getCorProxima,
  toolTrash,
} from "./components/Tools";

function App() {
  const [selectedColor, setSelectedColor] = useState({});
  const [selectedTool, setSelectedTool] = useState(
    () => () => console.log("empty tool")
  );
  const [toolName, settoolName] = useState("empty");
  const [padraoCores, setPadraoCores] = useState([]);
  const [colorsUsed, setScolorsUsed] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPickerResults, setSearchPickerResults] = useState([]);
  const [selectedPickerColor, setSelectedPickerColor] = useState();
  const [
    selectedPickerComplementaryColors,
    setSelectedPickerComplementaryColors,
  ] = useState([]);

  const [showSelectOption, setShowSelectOption] = useState(true);
  const options = [
    { value: "fachada", label: "Fachada", img: "fachada.png" },
    { value: "quarto", label: "Quarto", img: "quarto.png" },
    { value: "Sala", label: "Sala", img: "sala.png" },
    { value: "Cozinha", label: "Cozinha", img: "cozinha.png" },
  ];

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (!(/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) || window.MSStream) {
      window.location.href = 'https://simuladorfutura.cloudtinting.com.br';
    }
  }, []);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const changeColor = (codigoCor, colorUsed, r, g, b) => {
    var canvas = document.getElementById("canvas");
    var context = canvas.getContext("2d");

    var color = colorUsed || `rgb(${r},${g},${b}, 1)`;
    context.fillStyle = color;
    context.strokeStyle = color;

    context.lineWidth = 10;

    setSelectedColor({ color, codigoCor });

    if (toolName === "toolPickColor") {
      let compColor = new compColors(rgbToHex(r, g, b)).complementary()[1];
      let tetradicColors = new compColors(rgbToHex(r, g, b)).tetradic();

      let coresCombinacao = getCorProxima(padraoCores, compColor).slice(0, 1);

      let tetradicColor1 = getCorProxima(padraoCores, tetradicColors[1]).slice(
        0,
        1
      )[0];
      let tetradicColor2 = getCorProxima(padraoCores, tetradicColors[2]).slice(
        0,
        1
      )[0];
      let tetradicColor3 = getCorProxima(padraoCores, tetradicColors[3]).slice(
        0,
        1
      )[0];

      coresCombinacao = [
        ...coresCombinacao,
        tetradicColor1,
        tetradicColor2,
        tetradicColor3,
      ];

      setSelectedPickerComplementaryColors(coresCombinacao);
    }

    var canvasRef = document.getElementById("canvasIMG");
    var contextRef = canvasRef.getContext("2d");

    contextRef.fillStyle = color;

    if (toolName === "toolFill") {
      toolFill();
    }
  };

  const changePickerColor = (color) => {
    setSelectedColor({ color });

    setSearchResults(
      padraoCores
        .map((padraoCor) => {
          padraoCor.deltaE = deltaE(
            [color.rgb.r, color.rgb.g, color.rgb.b],
            [padraoCor.r, padraoCor.g, padraoCor.b]
          );
          return padraoCor;
        })
        .sort((a, b) => a.deltaE - b.deltaE)
        .slice(0, 10)
    );
  };

  const canvasTouchStart = (event, onEnd) => {
    selectedTool(event, onEnd);
  };

  const toolPickColor = (event) => {
    var canvas = document.getElementById("canvasIMG");
    var context = canvas.getContext("2d");

    try {
      var colorPos = relativePos(event, context.canvas);
      var imageData = context.getImageData(colorPos.x, colorPos.y, 1, 1);
      var colorVals = imageData.data;

      var color = "rgb(";

      for (var i = 0; i < colorVals.length - 1; i++) {
        if (colorVals[i] < 30) {
          color += 34;
        } else {
          color += colorVals[i];
        }
        if (i < 2) color += ",";
      }
      color += ")";

      context.fillStyle = color;
      context.strokeStyle = color;

      setSelectedColor({ color });

      var colorrgb = color
        .replace(/[^\d,]/g, "")
        .split(",")
        .map((num) => {
          return parseFloat(num);
        });

      var padraoMap = padraoCores
        .map((padraoCor) => {
          padraoCor.deltaE = deltaE(colorrgb, [
            padraoCor.r,
            padraoCor.g,
            padraoCor.b,
          ]);
          return padraoCor;
        })
        .sort((a, b) => a.deltaE - b.deltaE)
        .slice(0, 10);

      if (padraoMap.length > 0) {
        var firstColor = padraoMap.slice(0, 1)[0];

        var codigoCor = firstColor.codigo;
        color = `rgb(${firstColor.r},${firstColor.g},${firstColor.b}, 1)`;

        setSelectedColor({ color, codigoCor });
      }

      setSearchPickerResults(padraoMap);
    } catch (e) {
      throw e;
    }
  };

  const setActive = (event) => {
    var elems = document.querySelectorAll("div.ToolButton.active");
    [].forEach.call(elems, function (el) {
      el.classList.remove("active");
    });
    event.target.classList.add("active");
  };

  const toggleColorPicker = () => {
    var div = document.getElementById("picker");
    div.style.display = div.style.display === "flex" ? "none" : "flex";
  };

  const canvasClick = () => {
    if (
      toolName !== "toolPickColor" &&
      !colorsUsed.find((x) => x.color === selectedColor.color)
    ) {
      var colors = colorsUsed;
      colors.push(selectedColor);

      setScolorsUsed([...new Set(colors)]);
    }
  };

  const canvasTouchMove = (e) => {
    if (toolName === "toolPickColor") {
      var canvas = document.getElementById("canvasIMG");
      var context = canvas.getContext("2d");

      var colorPos = relativePos(e, context.canvas);
      const pixel = context.getImageData(colorPos.x, colorPos.y, 1, 1);
      const data = pixel.data;

      const rgba = `rgba(${data[0]}, ${data[1]}, ${data[2]}, ${data[3] / 255})`;

      var zoom = document.getElementById("zoomBox");
      var zoomContext = zoom.getContext("2d");
      zoomContext.fillStyle = rgba;

      zoomContext.fillRect(0, 0, zoom.width, zoom.height);

      var pagePos = relativePos(e, document.getElementById("desktop"));
      zoom.style.top = pagePos.y + 10 + "px";
      zoom.style.left = pagePos.x + "px";
      zoom.style.display = "block";
    }
  };

  const canvasTouchEnd = () => {
    var zoom = document.getElementById("zoomBox");
    zoom.style.display = "none";
  };

  const colorFamilyClick = (familia) => {
    setSearchResults(
      padraoCores.filter((x) => x.familia?.toLowerCase() === familia)
    );
  };

  useEffect(() => {
    const protocol = window.location.protocol;
    fetch(`${protocol}//futura.cloudtinting.com.br/api/padraocores`)
      .then((response) => response.json())
      .then((data) => {
        setPadraoCores(data.filter(x => x.r > 0 || x.g > 0 || x.b > 0));
      });
  }, []);

  useEffect(() => {
    const results = padraoCores.filter(
      (padraoCor) =>
        padraoCor.descricao.toLowerCase().includes(searchTerm.toLowerCase()) ||
        padraoCor.codigo.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [padraoCores, searchTerm]);

  const saveImage = () => {
    var showLogo = document.getElementById("logoImagem");
    showLogo.style.display = "block";

    var shadowDesktop = document.getElementById("desktop");
    shadowDesktop.style.boxShadow = "none";

    // var shadowColors = document.getElementById("usedColors");
    // shadowColors.style.boxShadow = "none";

    var tools = document.getElementById("desktopTools");
    if (tools) tools.style.display = "none";

    html2canvas(document.getElementById("desktop")).then((canvas) =>
      window.open(canvas.toDataURL("image/png"), "_blank")
    );
    showLogo.style.display = "none";
    tools.style.display = "block";

    shadowDesktop.style.boxShadow = "";
    // shadowColors.style.boxShadow = "";
  };

  const selectStep2Change = (event) => {
    var canvas = document.getElementById("canvasIMG");
    var context = canvas.getContext("2d");

    var img = new Image();
    img.src = require("./assets/" + event.img);

    img.onload = () => {
      context.canvas.height = img.height;
      context.canvas.width = img.width;

      var style = window.getComputedStyle(canvas);
      context.canvas.height = style
        .getPropertyValue("height")
        .replace("px", "");
      context.canvas.width = style.getPropertyValue("width").replace("px", "");

      context.drawImage(img, 0, 0, context.canvas.width, context.canvas.height);

      canvas.style.background = `rgb(255, 255, 255, 1)`;
    };
  };


  const closeTutorialModal = (event) => {
    var modal = document.getElementById("modalTutorial");
    modal.style.display = "none";
  };
  return (
    <div className="App-Background">

      <div className="Tutorial-Alert" id="modalTutorial">
        <div className="closeButton" onClick={() => closeTutorialModal()}>X</div>

        <label>
          OLÁ, BEM - VINDO AO SIMULADOR DE CORES DA FUTURA TINTAS.
          PARA <span>UMA EXPERIÊNCIA COMPLETA, RECOMENTAMOS QUE VEJA O VÍDEO TUTORIAL ABAIXO</span>
        </label>

        <a style={{ backgroundColor: '#d1e7ed', color: '#1f87a7', border: '3px solid #1f87a7', borderRadius: '15px' }}
          href="https://cloudtinting.s3.sa-east-1.amazonaws.com/VideoTutorialSimuladorWebMobile.mp4"
          target="_blank" rel="noreferrer" onClick={() => closeTutorialModal()}>ASSISTIR VIDEO</a>
      </div>

      <div className="App-Header">
        <HeaderProcess
          setPickerSelectedTool={() => {
            settoolName("toolPickColor");
            setSelectedTool(() => toolPickColor);
          }}
          setSelectedTool={(tool) => setSelectedTool(() => tool)}
          selectedColor={selectedColor}
          settoolName={settoolName}
          setSelectedPickerColor={setSelectedPickerColor}
          setShowSelectOption={setShowSelectOption}
        />
      </div>

      <div className="App-Body">
        {toolName !== "toolPickColor" && (
          <div className="SearchColors" style={{ padding: '0 10px' }}>
            <p style={{ color: '#15348f', textAlign: 'center', fontWeight: 'bolder', fontSize: '20px' }}>CORES DISPONÍVEIS</p>
            {showSelectOption &&
              <>
                <label style={{ color: '#577d8e', width: '100%', fontWeight: 'bolder', fontSize: '12px' }}>SELECIONE O AMBIENTE</label>
                <div style={{ color: '#577d8e', width: '100%', fontWeight: 'bolder', fontSize: '12px', marginBottom: '15px' }}>
                  <Select
                    className="SelectBox"
                    id="selectAmbientes"
                    defaultValue={options[0]}
                    options={options}
                    onChange={(event) => selectStep2Change(event)}
                  />
                </div>
              </>
            }

            <label style={{ color: '#577d8e', width: '100%', fontWeight: 'bolder', fontSize: '11px' }}>DIGITE O CÓDIGO OU DESCRIÇÃO DA COR</label>
            <div className="SearchBox">
              <input
                type="text"
                value={searchTerm}
                onChange={handleChange}
              />
              {/* {<label
                className="SearchCancelButton"
                onClick={() => setSearchTerm("")}
              >X</label>} */}

              <div className="PickerButton" onClick={() => toggleColorPicker()}>
                MATIZ DE CORES
              </div>
            </div>

            <div className="ColorPicker" id="picker">
              <SketchPicker
                presetColors={[]}
                color={selectedColor.color}
                onChangeComplete={changePickerColor}
              />
              <button onClick={() => toggleColorPicker()} style={{ position: 'absolute', top: '5px', right: '5px', cursor: 'pointer' }}>
                X
              </button>
            </div>

            {padraoCores.length > 0 && (
              <div className="typeColorFamily">
                <input
                  className="ColorFamily"
                  type="button"
                  id="Suaves"
                  value="SUAVES"
                  style={{ backgroundColor: '#c4d9d8', color: '#4b9291', border: '2px solid #4b9291', borderRadius: '10px' }}
                  onClick={() => colorFamilyClick("suaves")}
                />
                <input
                  className="ColorFamily"
                  type="button"
                  id="Luminosas"
                  value="LUMINOSAS"
                  style={{ backgroundColor: '#c2dcd2', color: '#1a9758', border: '2px solid #1a9758', borderRadius: '10px' }}
                  onClick={() => colorFamilyClick("luminosas")}
                />
                <input
                  className="ColorFamily"
                  type="button"
                  id="Vibrantes"
                  value="VIBRANTES"
                  style={{ backgroundColor: '#ecdfbf', color: '#bc9e36', border: '2px solid #bc9e36', borderRadius: '10px' }}
                  onClick={() => colorFamilyClick("vibrantes")}
                />
                <input
                  className="ColorFamily"
                  type="button"
                  id="Intensas"
                  value="INTENSAS"
                  style={{ backgroundColor: '#87a2a4', color: '#212e47', border: '2px solid #212e47', borderRadius: '10px' }}
                  onClick={() => colorFamilyClick("intensas")}
                />
              </div>
            )}

            <div className={"ScrollColors " + (showSelectOption ? 'showSelectOption' : '')}>
              {searchResults.length === 0
                ? searchTerm === ""
                  ? "Carregando..."
                  : "Nenhum registro encontrado."
                : searchResults.map((padraoCor) => (
                  <div
                    className="ColorSquare"
                    style={{
                      backgroundColor: `rgb(${padraoCor.r}, ${padraoCor.g}, ${padraoCor.b})`,
                    }}
                    key={padraoCor.id.toString()}
                    onClick={() =>
                      changeColor(
                        padraoCor.codigo,
                        null,
                        padraoCor.r,
                        padraoCor.g,
                        padraoCor.b
                      )
                    }
                  >
                    <div className="ColorLabel">
                      {padraoCor.codigo} <br /> {padraoCor.descricao}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}

        {selectedPickerColor && (
          <div className="SearchColors">
            <div
              className="BotaoVoltar"
              title="Voltar"
              onClick={() => setSelectedPickerColor()}
            >
              <span className="SetaVoltar"></span>
            </div>
            <div style={{ marginBottom: "15px", width: '100%', textAlign: "center" }}>
              <label style={{ color: '#577d8e', width: '100%', fontWeight: 'bolder', fontSize: '16px', padding: '0px 10px' }}>COMBINAÇÃO DE CORES</label>
            </div>
            <div className="ScrollColors ScrollColorsPicker">
              <div
                className="ColorSquare ColorSquareFirst"
                style={{
                  backgroundColor: `rgb(${selectedPickerColor.r}, ${selectedPickerColor.g}, ${selectedPickerColor.b})`,
                }}
                key={selectedPickerColor.id.toString()}
              >
                <div className="ColorLabel">
                  {selectedPickerColor.codigo} <br />{" "}
                  {selectedPickerColor.descricao}
                </div>
              </div>

              {selectedPickerComplementaryColors.map((padraoCor) => (
                <div
                  className="ColorSquare"
                  style={{
                    backgroundColor: `rgb(${padraoCor.r}, ${padraoCor.g}, ${padraoCor.b})`,
                  }}
                  key={padraoCor.id.toString()}
                >
                  <div className="ColorLabel">
                    {padraoCor.codigo} <br /> {padraoCor.descricao}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {toolName !== "toolPickColor" && (
          <div className="SelectedColor" id="SelectedColor"
            style={{ backgroundColor: selectedColor.color || "transparent" }}
            title="Cor selecionada">
          </div>
        )}

        <div id="desktopTools" className="Desktop pt-3">
          <div id="tools" className="Tools">
            <div className="ToolButton"
              id="toolPincel"
              onClick={(event) => {
                settoolName("toolBruch");
                setSelectedTool((event, onEnd) => toolBruch);
                setActive(event);
              }}>
              <Pincel />
              <label>PINCEL</label>
            </div>

            {<div className="ToolButton"
              onClick={(event) => {
                settoolName("toolFill");
                setSelectedTool((event, onEnd) => toolFill);
                setActive(event);
              }}>
              <Rolo />
              <label>PINTAR ÁREA</label>
            </div>}

            <div className="ToolButton"
              onClick={(event) => {
                fillVector();
                resetVector();
                settoolName("toolVector");
                setSelectedTool((event, onEnd) => toolVector);
                setActive(event);
              }}>
              <Vetor
                id="vetor"
              />
              <label>SELECIONAR ÁREA</label>
              <div id="confirmarVetor" className="ConfirmarVetor"
                onClick={() => {
                  fillVector();
                  resetVector();
                }}>
                <Confirmar />
              </div>
            </div>

            <div className="ToolButton" onClick={undoChanges}>
              <Undo />
              <label>DESFAZER</label>
            </div>

            <div className="ToolButton"
              onClick={(event) => {
                settoolName("toolErase");
                setSelectedTool((event, onEnd) => toolErase);
                setActive(event);
              }}>
              <Borracha />
              <label>APAGAR ALTERAÇÕES</label>
            </div>
            <div className="ToolButton"
              onClick={() => {
                setScolorsUsed([]);
                toolTrash();
              }}>
              <Trash />
              <label>LIMPAR IMAGEM</label>
            </div>

            <div className="ToolButton" onClick={() => saveImage()} >
              <Save />
              <label>DOWNLOAD IMAGEM</label>
            </div>

            {/* {esconderCoresUsadas ? (
                <div className="ToolButton"
                  onClick={() =>
                    minimizaCoresUsadas(esconderCoresUsadas, setEsconderCoresUsadas)
                  }>
                  <MostraCoresUsadas
                    id="mostraCoresUsadas"
                  />
                  <label>MOSTRAR CORES UTILIZADAS</label>
                </div>
              ) : (
                <div className="ToolButton"
                  onClick={() =>
                    minimizaCoresUsadas(esconderCoresUsadas, setEsconderCoresUsadas)
                  }>
                  <EscondeCoresUsadas
                    id="escondeCoresUsadas"
                  />
                  <label>ESCONDER CORES UTILIZADAS</label>
                </div>
              )} */}
          </div>
        </div>

        {toolName === "toolPickColor" && !selectedPickerColor && (
          <div className="SearchColors">
            <div className="ScrollColors">
              {searchPickerResults.length === 0 ? (
                <div style={{ marginTop: "20px" }}>
                  Capture a cor na imagem carregada
                </div>
              ) : (
                <div style={{ margin: "20px", width: '100%', textAlign: "center" }}>
                  <label style={{ color: '#577d8e', width: '100%', fontWeight: 'bolder', fontSize: '16px', padding: '0px 10px' }}>CORES DISPONÍVEIS</label>
                </div>
              )}
              {searchPickerResults.slice(0, 1).map((padraoCor) => (
                <div
                  className="ColorSquare ColorSquareFirst"
                  style={{
                    backgroundColor: `rgb(${padraoCor.r}, ${padraoCor.g}, ${padraoCor.b})`,
                  }}
                  key={padraoCor.id.toString()}
                  onClick={() => {
                    setSelectedPickerColor(padraoCor);
                    changeColor(
                      padraoCor.codigo,
                      null,
                      padraoCor.r,
                      padraoCor.g,
                      padraoCor.b
                    );
                  }}
                >
                  <div className="ColorLabel">
                    {padraoCor.codigo} <br /> {padraoCor.descricao}
                  </div>
                </div>
              ))}
              {searchPickerResults.slice(1).map((padraoCor) => (
                <div
                  className="ColorSquare"
                  style={{
                    backgroundColor: `rgb(${padraoCor.r}, ${padraoCor.g}, ${padraoCor.b})`,
                  }}
                  key={padraoCor.id.toString()}
                  onClick={() => {
                    setSelectedPickerColor(padraoCor);
                    changeColor(
                      padraoCor.codigo,
                      null,
                      padraoCor.r,
                      padraoCor.g,
                      padraoCor.b
                    );
                  }}
                >
                  <div className="ColorLabel">
                    {padraoCor.codigo} <br /> {padraoCor.descricao}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div id="desktop" className="Desktop">
          <div id="logoImagem" className="LogoImagem">
            <img src={logo} width={150} height={60} alt="logo" />
          </div>

          <canvas
            id="canvasIMG"
            className="CanvasIMG"
            width={360}
            height={600}
          ></canvas>

          <canvas
            id="canvas"
            className="Canvas"
            onClick={canvasClick}
            onTouchStart={canvasTouchStart}
            onTouchMove={canvasTouchMove}
            onTouchEnd={canvasTouchEnd}
          ></canvas>

          <canvas
            id="zoomBox"
            className="ZoomBox"
            width={60}
            height={60}
          ></canvas>

          {toolName !== "toolPickColor" && (
            <div id="usedColors" className="UsedColors">
              {colorsUsed.map((color, index) => (
                <div
                  key={index}
                  height="100px"
                  style={{ backgroundColor: color.color }}
                  title={color.codigoCor}
                  onClick={() => changeColor(color.codigoCor, color.color)}
                >
                  <span>{color.codigoCor}</span>
                </div>
              ))}
            </div>
          )}

        </div>
      </div>
    </div >
  );
}

export default App;
